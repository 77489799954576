<template>
  <v-overlay :value="loading" absolute color="white" :opacity="0.9">
    <p class="text--disabled text-end ma-0 pa-0 code-metamask">{{ $t('tokens.titles.waitingFor') }}</p>
    <v-img :src="require('../../../assets/img/metamask-logo.svg')" contain />
    <v-progress-linear class="mt-1" indeterminate color="#f5841f"/>
  </v-overlay>
</template>

<script>
  export default {
    name: 'OverlayLoadingMetamask',

    props: {
      loading: {
        type: Boolean,
        required: true,
        default: false
      }
    }
  }
</script>

<style scoped>

</style>