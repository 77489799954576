import {mapActions, mapGetters} from "vuex";

export const locationMixin = {
  data: () => ({

  }),

  computed: {
    ...mapGetters({
        locations: "locations/locationsList"
    })
  },

  methods: {
    ...mapActions({
      fetchPrimaryLocations: "locations/fetchFilteredLocations"
    })
  }
}