<template>
  <v-card elevation='0' class="my-1">
    <v-card-text>
      <v-btn-toggle v-model='filter' borderless
        class='d-flex justify-center align-center align-content-center'
        style='background-color: #03ac8c; z-index: 100'>
        <v-btn active-class='btn__selected' color='secondary darken-4' text value='general' @click='fetchHistoryData()'
          :disabled='!token || loading'>
          <span :class='{ "white--text": filter === "general" }' class='hidden-sm-and-down font-weight-bold'>{{
              $t('tokens.buttons.general')
          }}</span>

          <v-icon color='#005B4A' right size='20px'>
            mdi-timeline-text
          </v-icon>
        </v-btn>

        <v-btn active-class='btn__selected' color='secondary darken-4' text value='send' @click='fetchHistoryData()'
          :disabled='!token || loading'>
          <span :class='{ "white--text": filter === "send" }' class='hidden-sm-and-down font-weight-bold'>{{
              $t('tokens.buttons.send')
          }}</span>

          <v-icon color='#005B4A' right size='20px'>
            mdi-arrow-right-box
          </v-icon>
        </v-btn>

        <v-btn active-class='btn__selected' color='secondary darken-4' text value='received' @click='fetchHistoryData()'
          :disabled='!token || loading'>
          <span :class='{ "white--text": filter === "received" }' class='hidden-sm-and-down font-weight-bold'>{{
              $t('tokens.buttons.received')
          }}</span>

          <v-icon color='#005B4A' right size='20px'>
            mdi-arrow-left-box
          </v-icon>
        </v-btn>

        <v-btn active-class='btn__selected' color='secondary darken-4' text value='settle' @click='fetchHistoryData()'
          :disabled='!token || loading'>
          <span :class='{ "white--text": filter === "settle" }' class='hidden-sm-and-down font-weight-bold'>{{
              $t('tokens.buttons.settled')
          }}</span>

          <v-icon color='#005B4A' right size='20px'>
            mdi-file-certificate
          </v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-row class='my-4'>
        <v-col v-scroll.self='onScroll' cols='12' style='overflow-y: scroll !important; height: 47vh'>
          <v-overlay absolute color='white' v-model='loading'>
            <v-progress-circular color='secondary' indeterminate />
          </v-overlay>
          <v-timeline v-if='tracking && tracking.length > 0' align-top dense>
            <v-timeline-item class="my-2" v-for='(tx, index) in tracking' :key='index' color='white' small>
              <template v-slot:icon>
                <v-icon class='pa-1 white' color='#005B4A' small>{{ setIcon(tx.action) }}</v-icon>
              </template>
              <ItemComponent :tx="tx" :token="token" />
            </v-timeline-item>
          </v-timeline>
          <p v-if='!(tracking.length > 0) && !loading'
            class='text-center ma-0 pa-0 black--text font-weight-bold text-uppercase'>{{ $t('tokens.titles.nothing') }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ItemComponent from "./history/ItemComponent"

export default {
  name: "WalletHistoryComponent",
  components: { ItemComponent },
  props: {
    token: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      filter: 'general',
      scrollInvoked: 0,
      loading: false
    }
  },

  async mounted() {
    this.$root.$on("fetchWalletHistory", async () => {
      await this.fetchHistoryData()
    })
  },

  computed: {
    ...mapGetters({
      tracking: 'web3/GET_WALLET_TRACKING',
    })
  },

  methods: {
    ...mapActions({
      fetchTrackingWalletByAction: 'web3/FETCH_TRACKING_BY_WALLET'
    }),
    async fetchHistoryData() {
      this.loading = true

      setTimeout(async () => {
        switch (this.filter) {
          case 'general':
            await this.fetchTrackingWalletByAction(['GENERAL', window.userAddress, this.token.id])
            break
          case 'send':
            await this.fetchTrackingWalletByAction(['SEND', window.userAddress, this.token.id])
            break
          case 'received':
            await this.fetchTrackingWalletByAction(['RECEIVED', window.userAddress, this.token.id])
            break
          case 'settle':
            await this.fetchTrackingWalletByAction(['SETTLE', window.userAddress, this.token.id])
            break
        }

        this.loading = false
      }, 1000)
    },
    async downloadReport(id) {
      await this.generateReport({
        language: LanguageService.getLenguajeName(),
        logId: id
      })
    },
    setIcon(type) {
      switch (type) {
        case 'SEND':
          return 'mdi-arrow-right-box'
        case 'RECEIVED':
          return 'mdi-arrow-left-box'
        case 'SETTLE':
          return 'mdi-file-certificate'
      }
    },
    onScroll() {
      this.scrollInvoked++
    },
  },

  destroyed() {
    this.$root.$off('fetchWalletHistory')
  }
}
</script>

<style scoped>
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0 !important;
}

.v-btn--active.btn__selected i {
  color: white !important;
  background-color: transparent !important;
}

.theme--light.v-timeline::before {
  background: #005B4A !important;
}

.v-btn--active.btn__selected i {
  color: white !important;
  background-color: transparent !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3em !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #005b4a;
  border-radius: 3em !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #007c65;
}
</style>