<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="420"
  >
    <v-card>
      <v-card-title>
        {{  }}
      </v-card-title>
      <v-card-text>
        {{ $t("tokens.notification.compensationNotReady") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="$emit('onNotReady', false)"
        >
          {{ $t("general.buttons.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'NotReadyComponent',
    props: {
      item: {
        type: Object,
        require: false
      },
      dialog: {
        type: Boolean,
        require: false
      }
    }
  }
</script>

<style scoped>

</style>