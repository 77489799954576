<template>
  <v-row v-if="tx">
    <v-col class='pr-6 pb-0 d-flex justify-space-between align-content-center align-center' cols='12'>
      <p class='tx__title my-0 py-0'><strong>{{ $t(translateAction(tx.action)) }}</strong></p>
      <v-tooltip v-if='tx.action === "SETTLE"' left content-class='tooltip__styles'>
        <template v-slot:activator='{ on, attrs }'>
          <v-btn v-on='on' v-bind='attrs' small elevation='0' @click="downloadReport(tx.id)" :loading="loading">
            <template v-slot:loader>
              <v-progress-circular size="10" width="2" color="secondary" indeterminate />
            </template>
            <v-icon small color='grey darken-1'>
              mdi-file-download
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('general.buttons.download') }} {{ $t('tokens.titles.certificate') }}</span>
      </v-tooltip>
    </v-col>
    <v-col class='my-0 py-0' cols='4'>
      <div style='width: 200px' v-if='["SEND", "RECEIVED", "SETTLE"].includes(tx.action)'>
        <p class='tx__text my-0 py-0'><strong>{{ $t('tokens.titles.from') }}: </strong>{{ tx.from |
            truncateAddress(6)
        }}</p>
        <p class='tx__text my-0 py-0'><strong>{{ $t('tokens.titles.to') }}: </strong>{{ tx.to |
            truncateAddress(6)
        }}</p>
        <a class='lot__chip my-1 py-1 d-flex justify-start' target="_blank"
          :href="`${polygonNet}/token/${token.contractAddress}?a=${tx.nftRef}`">
          <strong>NFT: </strong> {{ tx.lot | truncate(10) }}
          <v-icon color="secondary" small right>
            mdi-link
          </v-icon>
        </a>

      </div>
      <div style='width: 200px' v-else>
        <p class='tx__text my-0 py-0'><strong>{{ $t('tokens.titles.from') }}: </strong>{{ tx.from |
            truncateAddress(6)
        }}</p>
      </div>
    </v-col>
    <v-col class='my-0 py-0' cols='5'>
      <p class='tx__text my-0 py-0'><strong>{{ $t('tokens.titles.date') }}: </strong>{{
          parseDate(tx.logDate)
      }}</p>
      <span class="d-flex justify-start align-content-center align-center">
        <p class='tx__text my-0 py-0'><strong>tx: &nbsp;</strong></p>
        <a :href="`${polygonNet}/tx/${tx.tx_hash}`" target="_blank">
          <span>
            {{ tx.tx_hash | truncate(10) }}
          </span>
        </a>
      </span>
    </v-col>
    <v-col class='my-0 py-0' cols='3'>
      <p class='tx__text my-0 py-0'><strong>{{ $t('tokens.titles.amount') }}: </strong>{{ tx.amount
      }}<strong>
          {{ token.symbol }}</strong></p>
    </v-col>
    <NotReadyComponent @onNotReady='dialog = $event' :dialog='dialog' />
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import LanguageService from "@/services/LanguajeService"
import NotReadyComponent from '@/module/tokens/wallet/components/history/NotReadyComponent'

export default {
  name: "ItemComponent",
  components: { NotReadyComponent },
  props: {
    token: {
      type: Object,
      required: true
    },
    tx: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      polygonNet: process.env.VUE_APP_POLYGON_SCAN,
      dialog: false
    }
  },

  methods: {
    ...mapActions({
      generateReport: 'web3/GENERATE_REPORT'
    }),
    async downloadReport(id) {
      this.loading = true
      const isReady = await this.generateReport({
        language: LanguageService.getLenguajeName(),
        logId: id
      })

      if(!isReady)
        this.dialog = true

      this.loading = false
    },
    translateAction(action) {
      switch (action) {
        case "SEND":
          return 'tokens.text.send'
        case "EMIT":
          return 'tokens.text.emit'
        case "BURN":
          return 'tokens.text.burn'
        case "RECEIVED":
          return 'tokens.text.received'
        case "SETTLE":
          return 'tokens.text.settle'
      }
    },
    parseDate(date) {
      return (new Date(date)).toLocaleString()
    },
  }
}

</script>

<style scoped>
.lot__chip {
  padding: 6px 12px;
  border-radius: .3em;
  font-size: 12px;
  font-weight: 500;
  background: #f3f3f3;
  color: #1a1a1a;
  width: 140px;
}

a {
  text-decoration: none;
}

.tx__title {
  color: #373737;
  font-weight: bold;
  font-size: 16px;
}

.tx__text {
  color: #373737;
}
</style>