import {mapActions, mapGetters} from "vuex";

export const storageMixin = {
  data: () => ({

  }),

  computed: {
    ...mapGetters({
        storages: "storage/storagesList"
    })
  },

  methods: {
    ...mapActions({
      fetchStorageLocations: "storage/fetchFilteredStorages"
    })
  }
}